import React from "react"
import Layout from "../components/layout"
import { Helmet } from 'react-helmet';

const ContactPage = () => (
    <Layout>
        <Helmet>
            <title>Contact ManagersApp</title>
            <meta name="description" content="We love to hear from our customers. Let us know how we can help"></meta>
        </Helmet>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Contact</h1>
                </div>
                <div className={"row"}>
                    <div className={"col-7"}>
                        <p>If you need our help with your user account, have questions about how to use the ManagersApp, any feedback or are experiencing technical difficulties, please do not hesitate to contact us.</p>
                    </div>
                    <div className={"col-5"}>
                        <div className={"contact-items"}>
                            <p>Plain email is the best way to contact us.</p>
                            <h2>contact@managers.app</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
